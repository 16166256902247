<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'OrganizationHome',
              }"
            >
              組織資料設定
            </b-breadcrumb-item>
            <b-breadcrumb-item active>檔案列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between flex-row-reverse">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.FILE_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'UploadFileCreate' }"
            ><i class="fa fa-plus"></i>新增檔案</b-button
          >
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              placeholder="搜尋檔案名稱"
              v-model="keyword"
              v-on:keyup.enter="getUploadFiles()"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getUploadFiles()"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-b-toggle.collapse-1-inner
            variant="link"
            class="flex-shrink-0 ml-0 ml-xl-1"
          >
            進階搜尋<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-input
                v-model="keyword"
                placeholder="搜尋檔案名稱"
              ></b-form-input>
            </div>
            <div class="d-flex flex-column flex-xl-row mt-2">
              <!-- 時間區間 -->
              <DateSearchPicker
                class="mr-md-2 mr-0"
                v-model="query"
                :disabledEndAtDates.sync="disabledEndAtDates"
                startedPlaceholder="Start Date"
                endedPlaceholder="End Date"
              />
            </div>
            <div class="d-flex flex-column flex-xl-row mt-2">
              <b-form-group
                label="File Type"
                label-cols-sm="1"
                content-cols-sm
                content-cols-lg="11"
                style="width: 100%"
              >
                <b-form-checkbox-group
                  id="extension-selected"
                  v-model="query.extension"
                  class="pt-1"
                  :options="extensionOptions"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>

            <div class="flex-column mt-2">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="getUploadFiles"
                ><i class="fa fa-search"></i>搜尋</b-button
              >
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
                >重置搜尋條件</b-button
              >
            </div>
          </b-form>
        </div>
      </b-collapse>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="uploadFiles"
                :fields="fields"
              >
                <template #cell(info)="data">
                  <div class="row">
                    <div class="col-2 mr-3" v-if="data.item.file.extension">
                      {{ data.item.file.extension.toUpperCase() }}
                    </div>
                    <div class="copy-panel col-2">
                      <div
                        id="copy-tooltip-target"
                        class="copy-button"
                        v-clipboard:copy="data.item.liff_url"
                        @click="clipboardSuccessHandler(data.item)"
                        v-b-tooltip.click="'已複製'"
                      >
                        <i
                          :class="copyIconClass(data.item)"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.FILE_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'UploadFileView',
                      params: { upload_file_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.FILE_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'UploadFileEdit',
                      params: { upload_file_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.FILE_DELETE])"
                    variant="inverse-danger"
                    @click="deleteUploadFile(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="getUploadFiles"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import uploadFileApi from "@/apis/upload-file";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import moment from "moment";
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker";

Vue.use(VueClipboard);

export default {
  components: {
    DateSearchPicker,
  },
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      uploadFiles: [],
      uploadFile: null,
      keyword: "",
      categoryOptions: [],
      query: {
        start_at: "",
        end_at: "",
        extension: ['pdf', 'xlsx', 'ppt', 'doc', 'video', 'image', 'txt', 'other']
      },
      extensionOptions: [
        {
          value: "pdf",
          text: "PDF",
        },
        {
          value: "xlsx",
          text: "XLSX",
        },
        {
          value: "ppt",
          text: "PPT",
        },
        {
          value: "doc",
          text: "DOC",
        },
        {
          value: "video",
          text: "Video",
        },
        {
          value: "image",
          text: "Image",
        },
        {
          value: "txt",
          text: "TXT",
        },
        {
          value: "other",
          text: "Other",
        }
      ],
      fields: [
        {
          key: "name",
          label: "File Name",
        },
        {
          key: "description",
          label: "Description",
          formatter: (value) => {
            value = value || "";
            const splittedValue = [...value];
            if (splittedValue.length <= 10) {
              return value;
            }
            return splittedValue.slice(0, 10).join("") + "...";
          },
        },
        {
          key: "file.size",
          label: "Size",
          formatter: (value) => {
            if (value) return (value / 1024 / 1024).toFixed(2) + " MB";
            return "-";
          },
        },
        {
          key: "staff.name",
          label: "Uploader",
        },
        {
          key: "info",
          label: "File Type",
        },
        {
          key: "category",
          label: "Document Type",
          formatter: (value) => {
            if (value) {
              if (
                this.categoryOptions.find((options) => options.value === value)
              ) {
                return this.categoryOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return "無法顯示";
              }
            }
          },
        },
        {
          key: "start_at",
          label: "Start Date",
          formatter: (value) => {
            if (value) return moment(value).format("YYYY-MM-DD");
            return "-";
          },
        },
        {
          key: "end_at",
          label: "End Date",
          formatter: (value) => {
            if (value) return moment(value).format("YYYY-MM-DD");
            return "-";
          },
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    disabledEndAtDates() {
      return { to: new Date(this.query.start_at) };
    },
  },
  watch: {
    currentPage() {
      this.getUploadFiles();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    copyIconClass(item) {
      if (!item.copied) {
        return "fa fa-copy";
      } else {
        return "fa fa-check";
      }
    },
    resetSearch() {
      this.uploadFile = null;
      this.keyword = "";
      (this.query = {
        start_at: "",
        end_at: "",
      }),
        this.getUploadFiles();
    },

    async init() {
      await this.getUploadFiles();
      this.fetchCategoryOptions();
    },
    fetchCategoryOptions() {
      const data = this.dashboardModule?.upload_files?.upload_files_category;
      if (!data) return;
      this.categoryOptions = [
        ...data
          .sort((a, b) => a.order - b.order)
          .map((source) => {
            return {
              text: source.text,
              value: source.key,
            };
          }),
      ];
    },
    async getUploadFiles() {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: this.currentPage,
          uploadFile: this.uploadFile,
          keyword: this.keyword,
          start_at: this.query.start_at,
          end_at: this.query.end_at,
          extension: this.query.extension
        };

        const { data } = await uploadFileApi.getUploadFiles(
          this.organization,
          params
        );
        this.uploadFiles = data.data.map((data) => {
          return {
            ...data,
            copied: false,
          };
        });
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得檔案資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteUploadFile(uploadFile) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>檔案：${uploadFile.name}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await uploadFileApi.removeUploadFile(
              this.organization,
              uploadFile
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + uploadFile.name,
              });
              this.getUploadFiles();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    clipboardSuccessHandler(item) {
      item.copied = true;
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}

.copy-button {
  background: rgba(102, 153, 204, 0.03);
  border: 1px solid rgba(102, 153, 204, 0.6);
  color: #3973ac;
  padding: 4px 16px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  position: absolute;
  top: -7px;
}

.copy-panel {
  position: relative;
}
</style>
